.footer {
  background-color: #F6F6F6;
  font-size: 16px;
  line-height: 130%;

  &__inner {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 25px 0;
    gap: 16px;

    @include respond-max('mobile') {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__caption {
    @include respond-min('mobile') {
      text-align: center;
    }

    @include respond-max('mobile') {
      grid-column: 1/3;
      order: 1
    }
  }

  &__logo {
    text-align: right;
  }

  .icon-logo {
    width: 84px;
    height: 14px;

    @include respond-max('mobile') {
      width: 66px;
      height: 11px;
    }
  }

  .icon-logo_sk {
    width: 142px;
    height: 24px;

    @include respond-max('mobile') {
      width: 118px;
      height: 20px;
    }
  }
}
