.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px;

  @include respond-max('mobile') {
    padding: 0 15px
  }
}
