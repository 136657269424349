.projects {
  padding-top: 100px;

  @include respond-max('mobile') {
    padding-top: 40px;
  }

  h2 {
    text-align: left;
  }

  &__inner {
    background-color: #F7F7F7;
    padding: 100px 0;

    @include respond-max('mobile') {
      padding: 40px 0;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @include respond-max('mobile') {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.projects__item {
  &:last-child {
    @include respond-min('mobile') {
      margin-top: -124px
    }
  }

  &-img {
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 40px;

    @include respond-max('mobile') {
      margin-bottom: 24px;
    }
  }

  &-name {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 26px;

    @include respond-max('mobile') {
      font-size: 18px;
      margin-bottom: 18px;
    }
  }

  p {
    @include respond-min('mobile') {
      max-width: 510px
    }
  }

  &-link {
    margin-top: 26px;

    @include respond-min('mobile') {
      margin-top: 18px
    }
  }
}
