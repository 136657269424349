.adventages {
  padding-top: 200px;
  margin-top: -100px;

  @include respond-max('mobile') {
    padding-top: 100px;
    margin-top: -60px
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px 0;

    @include respond-max('mobile') {
      grid-template-columns: minmax(auto, 210px);
      justify-content: center;
      gap: 50px
    }
  }

  &__item {
    text-align: center;

    &-icon {
      width: 131px;
      height: 131px;
      margin: 0 auto 32px;

      @include respond-max('mobile') {
        width: 100px;
        height: 100px;
        margin: 0 auto 20px;
      }
    }
  }
}
