/**
 * Variables
 */

@import 'settings/colors';
@import 'settings/breakpoints';

/**
  * Functions, mixins
  */

@import 'tools/functions';
@import 'tools/mixins';

/**
 * Basic rules
 */

@import 'base/base';
@import 'base/fonts';

/**
 * Vendor
 */

@import 'vendor/import';

/**
 * Components
 */

@import 'components/button';
@import 'components/container';
@import 'components/header';
@import 'components/footer';
@import 'components/icon';
@import 'components/link';
@import 'components/section';
@import 'components/greeting';
@import 'components/adventages';
@import 'components/projects';
@import 'components/form';
@import 'components/main';
