.link {
  transition: 0.3s ease-out;

  @media (hover: hover) {
    &:hover {
      color: #0008D1
    }
  }

  &-arr {
    display: inline-flex;
    align-items: center;

    &:hover {
      .link-icon {
        background-color: #0008D1;
      }
    }
  }

  &-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #0034D1;
    border: 1px solid rgba(35, 35, 35, 0.1);
    display: flex;
    transition: 0.3s ease-out;
    margin-left: 26px;

    @include respond-max('mobile') {
      width: 70px;
      height: 70px;
      margin-left: 18px;
    }

    .icon {
      width: 24px;
      height: 24px;
      margin: auto;
    }
  }
}

a:hover {
  .link-icon {
    transform: rotate(-45deg);
  }
}
