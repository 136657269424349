html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-size: 18px;
  font-family: 'GraphikLCG', sans-serif;
  font-weight: 400;
  color: #212121;
  -webkit-font-smoothing: antialiased;

  @include respond-max('mobile') {
    font-size: 14px;
  }

  &.is-menu-open {
    overflow: hidden;
  }
}

*,
:before,
:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  -webkit-locale: auto;
}

svg {
  pointer-events: none;
}

input,
textarea,
button {
  border: none;
  outline: none;
  border-radius: 0;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
input[type='number'],
textarea,
button {
  -webkit-appearance: none;
}


input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input::-ms-reveal,
input::-ms-clear {
  width: 0;
  height: 0;
}

fieldset {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  object-fit: cover;
}

label {
  cursor: pointer
}
