@font-face {
  font-family: 'GraphikLCG';
  src: url('../fonts/GraphikLCG-Medium.woff2') format('woff2'),
    url('../fonts/GraphikLCG-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'GraphikLCG';
  src: url('../fonts/GraphikLCG-Regular.woff2') format('woff2'),
    url('../fonts/GraphikLCG-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
