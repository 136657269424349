.section {
  &_bg-gray {
    background-color: #F7F7F7;
  }

  @include respond-max('mobile') {
    padding-top: 40px
  }

  &.feedback {
    padding: 200px 0 100px;
    margin-top: -100px;

    @include respond-max('mobile') {
      padding: 100px 0 40px;
      margin-top: -60px
    }
  }
}
