.header {
  position: fixed;
  font-size: 16px;
  line-height: 20px;
  padding: 28px 0 32px;
  border-bottom: 1px solid #ECECEC;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  top: 0px;

  &::after {
    content: '';
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 33, 33, 0.4);
    transition: 0.3s ease-out;
    visibility: hidden;
    opacity: 0;
  }

  @include respond-max('mobile') {
    padding: 18px 0 14px
  }

  &__logo a {
    display: block;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu-inner {
    background-color: #fff;

    @include respond-max('mobile') {
      position: fixed;
      z-index: 2;
      top: 0;
      right: 0;
      width: 283px;
      height: 100%;
      padding: 25px 30px 50px;
      transform: translateX(100%);
      transition: 0.3s ease-out;
    }
  }

  &__button {
    @include respond-min('mobile') {
      padding: 0 25px;
      background: #0034D1;
      border-radius: 30px;
      height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff
    }
  }

  menu {
    @include respond-min('mobile') {
      display: flex;
      align-items: center;
      margin: 0 -13px;
    }

    @include respond-max('mobile') {
      margin-top: 58px;
    }
  }

  li {
    padding: 0 13px;

    @include respond-max('mobile') {
      padding: 18px 0
    }

  }

  .button_mob {
    display: block;
    margin-left: auto;
    width: 28px;

    @include respond-min('mobile') {
      display: none;
    }
  }

  .icon-logo {
    width: 94px;
    height: 16px;

    @include respond-max('mobile') {
      width: 80px;
      height: 14px;
    }
  }
}

.is-menu-open {
  .header__menu-inner {
    transform: none;
  }

  .header::after {
    visibility: visible;
    opacity: 1;
  }
}
