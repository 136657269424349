.greeting {
  padding-top: 100px;

  @include respond-max('mobile') {
    padding-top: 30px;
  }

  &__head {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
    gap: 13px;
    margin-bottom: 40px;

    @include respond-max('desktop-small') {
      grid-template-columns: 1fr;
    }

    @include respond-max('mobile') {
      margin-bottom: 24px
    }
  }

  p {
    font-size: 20px;
    line-height: 130%;

    @include respond-min('desktop-small') {
      margin-bottom: 8px;
      max-width: 303px;
      margin-left: auto;
    }

    @include respond-max('mobile') {
      font-size: 16px;
    }
  }

  &__banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    padding-bottom: 43%
  }
}
