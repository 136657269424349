.form {
  margin: 0 auto;
  max-width: 720px;

  @include respond-min('mobile') {
    font-size: 16px;
  }

  input,
  textarea {
    background-color: #F6F6F6;
    border-radius: 5px;
    border: 1px solid #F6F6F6;
    padding: 14px 22px;
    width: 100%;

    @include respond-max('mobile') {
      padding: 16px 11px;
    }
  }

  textarea {
    resize: none;
    height: 132px
  }

  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }

  .is-error {

    input,
    textarea,
    .form__checkbox-input {
      border-color: #D62323;
    }
  }

  &__error {
    font-size: 14px;
    color: #D62323;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    align-items: flex-end;

    @include respond-max('mobile') {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__field {
    margin-bottom: 22px;

    @include respond-max('mobile') {
      margin-bottom: 12px;
    }
  }

  &__button {
    text-align: center;
    margin-top: 35px;

    @include respond-max('mobile') {
      text-align: left;
      margin-top: 20px
    }
  }
}

.form__checkbox {
  position: relative;
  display: block;

  &-row {
    display: grid;
    grid-template-columns: 24px minmax(auto, 470px);
    gap: 10px;
    justify-content: center;

    .form__error {
      grid-column: 1/4;
    }
  }

  input[type='checkbox']:checked+.form__checkbox-square {
    opacity: 1;
  }

  &-input {
    position: relative;
    height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    overflow: hidden;
  }

  &-square {
    width: 100%;
    height: 100%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px 11px;
    background-image: url('../images/svg/check.svg');
    opacity: 0;
    transition: 0.3s ease-out;
  }

  span {
    padding-top: 4px;
    color: rgba(33, 33, 33, 0.4);

    a {
      text-decoration: underline;
    }
  }
}
