main {
  padding-top: 106px;

  @include respond-max('mobile') {
    padding-top: 66px;
  }

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 68px;
    line-height: 110%;

    @include respond-max('mobile') {
      font-size: 36px;
    }
  }

  h2 {
    margin: 0 0 80px;
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    text-align: center;

    @include respond-max('mobile') {
      font-size: 24px;
      margin: 0 0 40px;
    }
  }
}
